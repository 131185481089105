<template>
  <vx-card>
    <!-- <sticker-panel :key="stickerKey"></sticker-panel> -->

    <template>
      <!-- Sticker POP UP -->
      <vs-popup
        class="holamundo"
        classContent="popup-example label-font"
        title="Set Sticker"
        :active.sync="stickerActive"
      >
        <tabular :key="positionKey" @setposition="positionOk"></tabular>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <template>
      <!-- REMOVE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="ยกเลิกรายการ"
        :active.sync="removeActive"
      >
        <!--  รหัส  -->
        <vs-row
          style="font-weight:bold; margin-top:30px;"
          class="row-form"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
            style="padding-left: 20px 30px;"
            >ยืนยันยกเลิกรายการ {{ this.removeCode }}</vs-col
          >
        </vs-row>

        <vs-row
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          class="row-form"
          vs-w="12"
          style="margin-top:30px;margin-bottom:30px;"
        >
          <vs-button
            @click="closeRemovePanel()"
            style="margin:10px;"
            color="danger"
            type="border"
            >ยกเลิก</vs-button
          >
          <vs-button
            @click="update()"
            style="margin:10px; width:150px;"
            color="success"
            type="filled"
            >ตกลง</vs-button
          >
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <!-- RENTAL PAYMENT POP UP -->
    <template>
      <vs-popup
        classContent="popup-example"
        title="วิธีชำระค่าเช่า"
        :active.sync="rentalPaymentActive"
      >
        <vs-row
          class="row-form"
          vs-w="12"
          style="margin-top:30px;margin-bottom:30px;"
        >
          <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label pl-20 pb-5"
          >วิธีชำระค่าเช่า</vs-col> -->

          <!-- วิธีชำระค่าเช่า -->
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
          >
            <div
              :class="[
                rentalPaymentMethod === 'เงินสด' ? 'choose' : 'notChoose'
              ]"
              @click="changeRentalPayment('เงินสด')"
            >
              เงินสด
            </div>
            <div
              :class="[
                rentalPaymentMethod === 'โอนเงิน' ? 'choose' : 'notChoose'
              ]"
              @click="changeRentalPayment('โอนเงิน')"
            >
              โอนเงิน
            </div>

          <div
            :class="[
              rentalPaymentMethod === 'Alipay/wechat' ? 'choose' : 'notChoose'
            ]"
            @click="changeRentalPayment('Alipay/wechat')"
          >
             Alipay/wechat
          </div>

            <div
              :class="[
                rentalPaymentMethod === 'บัตรเครดิต' ? 'choose' : 'notChoose'
              ]"
              @click="changeRentalPayment('บัตรเครดิต')"
            >
              บัตรเครดิต
            </div>
            <div
              :class="[
                rentalPaymentMethod === 'ช่องทางอื่น' ? 'choose' : 'notChoose'
              ]"
              @click="changeRentalPayment('ช่องทางอื่น')"
            >
              ช่องทางอื่น
            </div>
          </vs-col>

          <!-- Calendar Rental Payment -->

          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-10 pt-8"
          >

          ระบุวันที่รับเงิน&nbsp;&nbsp;&nbsp;

          <div  clas="pl-8" style="display:inline-block; " >


                    <span clas="pl-8" style="font-size: 1.2rem; ">{{this.format_ddmmyyy(calendarRentalPayment)}} </span>

                     <i
                        style= " cursor:pointer; font-size:20px; margin-left:5px; margin-right:5px;vertical-align:middle;  border-radius:10px; position:absolute;"
                        class="material-icons iconhover">
                        date_range
                    </i>

                    <v-date-picker
                    class="pl-4 py-4 rentalPay"
                      style="width:50px; padding : 0 !important; border: 0; position:absolute; padding-left: -10px;"
                      ref="rentalCalendar"
                      :locale="{ id: 'th', firstDayOfWeek: 2, masks: {  L: 'DD/MM/YYYY' } }"
                      mode="date"
                      :key="rentalPaymentCode"
                      v-model="calendarRentalPayment"
                    />

              </div>



          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-10 "
          >
              <div style="font-size: 0.8rem; color:#EA5455;">*** หากไม่ระบุวันที่รับเงิน ระบบจะบันทึกวันที่รับเงินเป็นวันนี้ ({{this.format_ddmmyyy(new Date())}})</div>
        </vs-col>


        </vs-row>

        <vs-row
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          class="row-form"
          vs-w="12"
          style="margin-top:10px;margin-bottom:30px;"
        >
          <vs-button
            @click="closePanel()"
            style="margin:10px;"
            color="danger"
            type="border"
            >ยกเลิก</vs-button
          >

          <vs-button
            @click="rentalPaymentUpdate()"
            style="margin:10px; width:150px;"
            color="success"
            type="filled"
            >ตกลง</vs-button
          >
        </vs-row>
      </vs-popup>
      <!--  BAIL PAYMENT POP UP -->
    </template>

    <!-- BAIL PAYMENT POP UP -->
    <template>
      <vs-popup
        classContent="popup-example"
        title="วิธีชำระค่าประกัน"
        :active.sync="bailPaymentActive"
      >
        <vs-row
          class="row-form"
          vs-w="12"
          style="margin-top:30px;margin-bottom:30px;"
        >
          <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
            class="form-label px-2"
          >วิธีชำระค่าประกัน</vs-col> -->

          <!-- วิธีชำระค่าประกัน -->

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
          >
            <div
              :class="[bailPaymentMethod === 'เงินสด' ? 'choose' : 'notChoose']"
              @click="changeBailPayment('เงินสด')"
            >
              เงินสด
            </div>
            <div
              :class="[
                bailPaymentMethod === 'โอนเงิน' ? 'choose' : 'notChoose'
              ]"
              @click="changeBailPayment('โอนเงิน')"
            >
              โอนเงิน
            </div>

            <div
            :class="[
              bailPaymentMethod === 'Alipay/wechat' ? 'choose' : 'notChoose'
            ]"
            @click="changeBailPayment('Alipay/wechat')"
          >
             Alipay/wechat
          </div>

            <div
              :class="[
                bailPaymentMethod === 'บัตรเครดิต' ? 'choose' : 'notChoose'
              ]"
              @click="changeBailPayment('บัตรเครดิต')"
            >
              บัตรเครดิต
            </div>
            <div
              :class="[
                bailPaymentMethod === 'ช่องทางอื่น' ? 'choose' : 'notChoose'
              ]"
              @click="changeBailPayment('ช่องทางอื่น')"
            >
              ช่องทางอื่น
            </div>
          </vs-col>

          <!-- Calendar Bail Payment -->

          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-10 pt-8"
          >

          ระบุวันที่รับเงิน&nbsp;&nbsp;&nbsp;


                    <div  clas="pl-8" style="display:inline-block; " >


                                <span clas="pl-8" style="font-size: 1.2rem; ">{{this.format_ddmmyyy(calendarBailPayment)}} </span>

                                <i
                                    style= " cursor:pointer; font-size:20px; margin-left:5px; margin-right:5px;vertical-align:middle;  border-radius:10px; position:absolute;"
                                    class="material-icons iconhover">
                                    date_range
                                </i>

                                <v-date-picker
                                class="pl-4 py-4 rentalPay"
                                  style="width:50px; padding : 0 !important; border: 0; position:absolute; padding-left: -10px;"
                                  ref="rentalCalendar"
                                  :locale="{ id: 'th', firstDayOfWeek: 2, masks: {  L: 'DD/MM/YYYY' } }"
                                  mode="date"
                                  :key="bailPaymentCode"
                                  v-model="calendarBailPayment"
                                />

                 </div>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-10 "
          >
              <div style="font-size: 0.8rem; color:#EA5455;">*** หากไม่ระบุวันที่รับเงิน ระบบจะบันทึกวันที่รับเงินเป็นวันนี้ ({{this.format_ddmmyyy(new Date())}})</div>
        </vs-col>

        </vs-row>

        <vs-row
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          class="row-form"
          vs-w="12"
          style="margin-top:30px;margin-bottom:30px;"
        >
          <vs-button
            @click="closePanel()"
            style="margin:10px;"
            color="danger"
            type="border"
            >ยกเลิก</vs-button
          >
          <vs-button
            @click="bailPaymentUpdate()"
            style="margin:10px; width:150px;"
            color="success"
            type="filled"
            >ตกลง</vs-button
          >
        </vs-row>
      </vs-popup>
    </template>
    <!--  RENTAL PAYMENT POP UP -->

    <!--  CHANGE STATUS POP UP -->
    <template>
      <!-- POP UP -->
      <vs-popup
        classContent="popup-example"
        title="เปลี่ยนสถานะ"
        :active.sync="popupActive"
      >
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->

        <vs-row
          class="row-form"
          vs-w="12"
          style="margin-top:30px;margin-bottom:30px;"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
            class="form-label px-2"
            >เปลี่ยนสถานะของรายการเช่าสินค้า</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <div
              :class="[state === 'รอคืนชุด' ? 'choose' : 'notChoose']"
              @click="changeStatus('รอคืนชุด')"
            >
              รับชุดแล้ว
            </div>
            <div
              :class="[state === 'ยกเลิก' ? 'choose' : 'notChoose']"
              @click="changeStatus('ยกเลิก')"
            >
              ยกเลิกรายการ
            </div>
          </vs-col>
        </vs-row>

        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->
        <vs-row
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          class="row-form"
          vs-w="12"
          style="margin-top:30px;margin-bottom:30px;"
        >
          <vs-button
            @click="closePanel()"
            style="margin:10px;"
            color="danger"
            type="border"
            >ยกเลิก</vs-button
          >
          <vs-button
            @click="update()"
            style="margin:10px;  width:150px;"
            color="success"
            type="filled"
            >ตกลง</vs-button
          >
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!--  CHANGE STATUS POP UP -->
    <template>
      <!-- POP UP -->
      <vs-popup
        classContent="popup-example"
        title="บันทึกข้อความ"
        :active.sync="notePopupActive"
      >
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->
        <!--  Add this to data part  (import vSelect from "vue-select")     "v-select": vSelect  -->
        <!--  :select >>> ...Selected /  :options >>> ...Options -->
        <vs-row
          class="row-form"
          vs-w="12"
          style="margin-top:30px;margin-bottom:30px;"
        >
          <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
            class="form-label px-2"
          >บันทึกข้อความ</vs-col> -->
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-15"
          >
            <vs-textarea v-model="remark"></vs-textarea>
          </vs-col>
        </vs-row>

        <vs-row
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          class="row-form"
          vs-w="12"
          style="margin-top:30px;margin-bottom:30px;"
        >
          <vs-button
            @click="closeNotePanel()"
            style="margin:10px;"
            color="danger"
            type="border"
            >ยกเลิก</vs-button
          >

          <vs-button
            @click="updatenote()"
            style="margin:10px; width:150px;"
            color="success"
            type="filled"
            >ตกลง</vs-button
          >
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- HEADER -->
    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="6"
        >วันที่รับชุด</vs-col
      >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="3"
        vs-sm="3"
        vs-xs="6"
      >
        <div
          class="grid-layout-container alignment-block"
          style="vertical-align:text-top ;width:100%;"
        >
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >
              <v-date-picker
                style="width:100%;font-size:14px;"
                :locale="{
                  id: 'th',
                  firstDayOfWeek: 2,
                  masks: { L: 'DD/MM/YYYY' }
                }"
                mode="range"
                v-model="calendarData"
              />
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="6"
        vs-sm="6"
        vs-xs="12"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
            class="px-2"
          >
            <vs-button
              @click="searchBtn()"
              style="width:100%;"
              color="primary"
              type="filled"
              >ค้นหา</vs-button
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
            class="px-2"
          >
            <vs-button
              @click="resetBtn()"
              style="width:100%;"
              color="warning"
              type="filled"
              >reset</vs-button
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="px-2"
          >
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <!-- BRANCHES -->
    <!-- *** BEGIN : 2020-11-28 : Search By branches -->
    <!-- <vs-row vs-w="12">
      <ul v-if="permission === 'admin'" class="demo-alignment">
        <li
          @click="theSearch($event, index)"
          class="choose"
          v-for="(b, index) in allBranch"
          :key="index"
        >{{ b.branchName }}</li>
      </ul>


      <ul v-else class="demo-alignment">
        <li
          @click="theSearch($event, index)"
          class="choose"
          v-for="(b, index) in getMyBranches"
          :key="index"
        >{{ b.branchName }}</li>
      </ul>
    </vs-row> -->
    <!-- *** END : 2020-11-28 : Search By branches -->

    <!-- BRANCHES -->
    <!-- <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="12"
        >

          <div style="background-color:white;">
            <vue-json-pretty
              :path="'root'"
              :showLength="true"
              :selectableType="'multiple'"
              :showLine="true"
              :highlightMouseoverNode="true"
              :data="calendarData"
            ></vue-json-pretty>
          </div>
        </vs-col>
    </vs-row>-->
    <!-- HEADER -->

    <!-- TABLE -->
    <vs-table
      style="z-index:1;"
      pagination
      max-items="20"
      search
      :data="orders"
    >
      <template style="z-index:1 !important;" slot="thead">
        <vs-th sort-key="updateDateTime">ลงรายการ</vs-th>
        <vs-th sort-key="orderNumber" style="width: 17%">เลข Order</vs-th>
        <!-- <vs-th sort-key="productItem">ชื่อสินค้า</vs-th> -->
        <vs-th sort-key="customerName">ข้อมูลชื่อผู้เช่า</vs-th>
        <vs-th sort-key="rentalPriceTotal" style="width:12%;">ยอดเช่าชุด</vs-th>
        <vs-th sort-key="bailTotal" style="width:12%;">เงินประกัน</vs-th>
        <vs-th sort-key="pickupDate">วันรับชุด</vs-th>
        <vs-th sort-key="returnDate">วันคืนชุด</vs-th>
        <!-- <vs-th sort-key="orderStatus">สถานะ</vs-th> -->
        <!-- <vs-th>วิธีชำระ</vs-th> -->

        <vs-th>การจัดการ</vs-th>
      </template>

      <template style="z-index:1 !important;" slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td>
            <vs-checkbox v-model="data[indextr].selected" @click="addSelectedList(indextr, $event)"></vs-checkbox>
          </vs-td>-->
          <vs-td>
            {{ fd(tr.updateDateTime) }}
            <br />
            {{ tr.updateBy }}

            <br />
            <!-- <div style="color:#9E0619; font-weight:light;">รายละเอียด</div> -->
            <br />
          </vs-td>
          <vs-td style="text-align:center;">
            <vs-button
              :href="getRedirectLink(tr.orderHeaderId)"
              target="blank"
              style="padding:0; font-size:11px; color: #2372CE; background-color: white !important; border: 0 white solid !important; font-weight:bold;"
              >{{ tr.orderNumber }}</vs-button
            >
            <br />
            {{ findBranchName(tr.branchId) }}
            <br />
            <vx-tooltip
              text="ช่องทางการรับสินค้า"
              style="border: 1px dotted black;"
            >
              <i
                style="font-size:20px; margin-left:5px; margin-right:5px;vertical-align:middle;  border-radius:10px; "
                class="material-icons iconhover"
                >local_shipping</i
              >
              {{ receiving(tr.receivingMethod) }}
            </vx-tooltip>

            <div
              @click="changeToReturn($event, tr, indextr)"
              class="changetoreturn"
            >
              รอรับชุด
            </div>
          </vs-td>
          <!-- <vs-td>ข้อมูลสินค้า</vs-td> -->
          <vs-td style="max-width:150px; font-family: mitr;">
            {{ tr.customerName }}
            <br />
            {{ tr.customerAddress }}
            <br />
            {{ tr.customerPhone }}
          </vs-td>

          <!-- ค่าเช่า ใช้ Net price -->
          <vs-td v-if="tr.netPrice > 0" style="padding-left:20px;">
            {{ formatPrice(tr.netPrice) }}
            <vx-tooltip text="ช่องทางชำระค่าเช่า">
              <div
                :class="showPaymentClass(tr.rentalPaymentMethod)"
                @click="openRentalPaymentPanel($event, tr, indextr)"
                class="bailPayment px-2 py-1"
              >
                {{ showPayment(tr.rentalPaymentMethod) }}
              </div>
            </vx-tooltip>
          </vs-td>
          <vs-td v-else style="padding-left:20px;">
            {{
              realRentalPrice(
                tr.rentalPriceTotal,
                tr.rentalDiscount,
                tr.promotionDiscount
              )
            }}
            <vx-tooltip text="ช่องทางชำระค่าเช่า">
              <div
                :class="showPaymentClass(tr.rentalPaymentMethod)"
                @click="openRentalPaymentPanel($event, tr, indextr)"
                class="bailPayment px-2 py-1"
              >
                {{ showPayment(tr.rentalPaymentMethod) }}
              </div>
            </vx-tooltip>
          </vs-td>

          <!-- ค่าประกัน ใช้ Net bail -->
          <vs-td v-if="tr.netBail > 0" style="padding-left:20px;"
            >{{ formatPrice2Point(tr.netPrice) }}
            <vx-tooltip text="ช่องทางชำระเงินประกัน">
              <div
                :class="showPaymentClass(tr.bailPaymentMethod)"
                @click="openBailPaymentPanel($event, tr, indextr)"
                class="bailPayment px-2 py-1"
              >
                {{ showPayment(tr.bailPaymentMethod) }}
              </div>
            </vx-tooltip>
          </vs-td>

          <vs-td v-else style="padding-left:20px;"
            >{{ realBail(tr.bailTotal, tr.bailDiscount) }}
            <vx-tooltip text="ช่องทางชำระเงินประกัน">
              <div
                :class="showPaymentClass(tr.bailPaymentMethod)"
                @click="openBailPaymentPanel($event, tr, indextr)"
                class="bailPayment px-2 py-1"
              >
                {{ showPayment(tr.bailPaymentMethod) }}
              </div>
            </vx-tooltip>
          </vs-td>

          <vs-td>{{ formatDate(tr.pickupDate) }}</vs-td>
          <vs-td>{{ formatDate(tr.returnDate) }}</vs-td>
          <!-- <vs-td>{{tr.orderStatus}}</vs-td> -->
          <!-- <vs-td style="width:10%">
            <div
              style="width:100%;"
              @click="openPaymentPanel($event, tr, indextr)"
              :class="showPaymentClass(tr.bailPaymentMethod)"
            >{{ showPayment(tr.bailPaymentMethod) }}</div>
          </vs-td> -->

          <vs-td>
            <vx-tooltip text="แก้ไขบิล" style="display:inline-block;">
            <i
              @click="editRental($event, tr.orderHeaderId)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
              >edit</i
            >
          </vx-tooltip>
            <!-- *** 21Dec2021 * -->
            <vx-tooltip text="โน้ตเพิ่มเติม" style="display:inline-block;">

            <i
              v-if="
                tr.remark1 === null ||
                  tr.remark1 === undefined ||
                  tr.remark1 === ''
              "
              @click="takeNote($event, tr)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
              >speaker_notes</i
            >

            <!-- *** 21Dec2021 * -->
            <i
              v-else
              @click="takeNote($event, tr)"
              style="color:#0069ff; font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
              >speaker_notes</i
            >
          </vx-tooltip>


            <vx-tooltip text="พิมพ์ใบจัดชุด" style="display:inline-block;">
            <i
            @click="
                redirectTo($event, '/pages/rec-page/', tr.orderHeaderId)
              "
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
              >inventory</i
            >
          </vx-tooltip>

            <br />

            <vx-tooltip text="พิมพ์ใบเสร็จ" style="display:inline-block;">

            <i
              @click="printRec($event, tr.orderHeaderId)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
              >print</i
            >

          </vx-tooltip>
          <vx-tooltip text="พิมพ์สติ๊กเกอร์สำหรับส่งของ" style="display:inline-block;">
            <i
              @click="
                redirectTo($event, '/pages/postal-sticker/', tr.orderHeaderId)
              "
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
              >local_post_office</i
            >
            </vx-tooltip>
            <!-- <i
              @click="printSticker($event, tr.orderHeaderId)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >receipt</i> -->
            <!-- <i
              v-if="$acl.check('admin')"
              @click="adminEdit($event, tr.orderHeaderId)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >face</i> -->
            <vx-tooltip text="ทำการยกเลิกบิล" style="display:inline-block;">
            <i
              v-if="$acl.check('owner')"
              @click="openRemovePanel($event, tr, indextr)"
              style="color:red; font-size:20px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
              >cancel_presentation</i
            >
            </vx-tooltip>
          </vs-td>

          <div style="width: 100%; background-color:pink; height:50px; "></div>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TABLE -->

    <br />
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import shapeFormat from "../../mixins/shapeFormat.js";
import Tabular from "./Components/Tabular.vue";
import VueJsonPretty from "vue-json-pretty";


// import { FunctionalCalendar } from "vue-functional-calendar";
// window.addEventListener("load", function() {
//   var x = document.getElementsByClassName("tr-expand ");
//   for (var ii = 0; ii < x.length; ii++) {
//     x[ii].innerHTML = "12";
//   }
// });
export default {
  mixins: [shapeFormat],
  data() {
    return {
      calendarRentalPayment: null,
      calendarBailPayment: null,
      rentalPaymentCode : 0,
      bailPaymentCode : 0,
      rentalCalendarShow: false,
      bailCalendarShow: false,

      calendarData: {},
      rentalPaymentActive: false,
      bailPaymentActive: false,
      positionKey: "abc",
      stickerActive: false,
      removeActive: false,
      removeCode: "",
      removeId: "",
      searchDateEnd: null,
      original: [],
      searchlist: [],
      permission: "",

      allDetail: [],
      deh: [],
      currentBranch: null,
      currentUser: "guest",
      roles: [],
      takenote: "",
      takenoteIndex: 0,
      theIndex: 0,
      //*** 21Dec2021 */
      currentObject: {},
      // >>>>>>>> BRANCH INFO >>>>>>>>
      branchCheckbox: [],
      branches: ["ลาดพร้าว", "เมืองทองธานี"],
      allBranch: [],
      branchSelect: [],
      // >>>>>>>> UPDATE INFO >>>>>>>>
      rentalPaymentMethod: "ไม่ระบุ",
      rentalPaymentChannel: "ไม่ระบุ",
      bailPaymentMethod: "ไม่ระบุ",
      bailPaymentChannel: "ไม่ระบุ",
      receivedBy: "",
      state: "รอรับชุด",

      // >>>>>>>> UPDATE INFO >>>>>>>>
      remark: "",

      // >>>>>>>> UPDATE INFO >>>>>>>>

      statusToReturn: { label: "รับชุดแล้ว", value: "รับชุดแล้ว" },
      statusToReturnOption: [
        { label: "รับชุดแล้ว", value: "รับชุดแล้ว" },
        { label: "ยกเลิก", value: "ยกเลิก" }
      ],
      notePopupActive: false,
      popupActive: false,
      searchDate: "",
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: { label: "เงินสด", value: "เงินสด" },
      payment_option: [
        "ไม่ระบุ",
        "เงินสด",
        "โอนเงิน",
        "Alipay/wechat" ,
        "บัตรเครดิต",
        "ช่องทางอื่น"
      ],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: [],
      orders: []
    };
  },
  computed: {
    //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch() {
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches() {
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission() {
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    }
    //  *** BEGIN : 2020-11-28 : Search By branches
  },
  async created() {
    // this.orders = this.orders.filter(x => x.orderStatus === "รอรับชุด");
    // this.originalList = this.orders;

    this.$vs.loading();

    this.currentUser = JSON.parse(localStorage.getItem("username"));
    this.roles = JSON.parse(localStorage.getItem("roles"));

    // EDIT BRANCH
    this.thisBranch = this.$store.state.auth.currentBranch;

    this.permission = this.checkPermission(this.roles);

    // EDIT BRANCH
    this.allBranch = await this.loadBranch();

    // var branch = null;
    var ord = [];

    // branch = this.getMyBranches;

    // if (this.myPermission === "admin") {
    //   ord = await this.loadAllOrderShare();

    //   for (var m = 0; m < this.allBranch.length; m++) {
    //     this.branchSelect[m] = true;
    //   }
    // } else {
    //   // EDIT BRANCH
    //   // branch = this.$store.state.auth.currentBranch;

    //   //  *** BEGIN : 2020-11-28 : Search By branches
    //   ord = await this.loadOrderByBranchShared(this.getMyBranches);
    //   //  *** END : 2020-11-28 : Search By branches

    //   // ord = await this.loadBranchOrderShare(branch.branchId);
    // }

    //  *** BEGIN : 2020-12-04 : load just branch occupied
    ord = await this.loadOrderByBranchObjectShared(this.getCurrentBranch);
    //  *** END : 2020-12-04 : load just branch occupied

    // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
    var theorder = await ord.filter(x => x.orderStatus === "รอรับชุด");
    var sorted = await this.descendingDateSortShare(theorder, "updateDateTime");
    // console.log("theorder >>> ", theorder);

    this.orders = await sorted;
    this.original = await sorted;
    this.searchlist = await sorted;

    // this.branchCheckbox = await this.allBranch;
    // console.log('this.branchCheckbox >>>> ' , this.getMyBranches);

    //  *** BEGIN : 2020-11-28 : Search By branches
    this.branchCheckbox = this.getMyBranches;
    //  *** END : 2020-11-28 : Search By branches

    this.$vs.loading.close();
  },
  async mounted() {
    //  console.log('MOUNTED() getCurrentBranches >>>> ',this.getCurrentBranch.branchName);
  },

  methods: {
    // showAlert() {
    //   // Use sweetalert2
    //   this.$swal("Good job!", "You clicked the button!", "success");
    // },
    fd(date) {
      var datestr = date.toString();
      var datepre = datestr.split("T");

      return datepre[0];
    },
    positionOk(value) {
      setTimeout(() => {
        if (value) {
          this.stickerActive = false;
        } else {
          this.stickerActive = true;
        }
      }, 1000);
    },
    openStickerPanel(event) {
      event.stopPropagation();
      this.positionKey = this.generateRandom(5);
      this.stickerActive = true;
    },
    closeStickerPanel(event) {
      event.stopPropagation();
      this.stickerActive = false;
    },
    openRemovePanel(event, order, index) {
      event.stopPropagation();
      this.bailPaymentMethod = order.bailPaymentMethod;
      this.orderHeaderId = order.orderHeaderId;
      this.removeCode = order.orderNumber;

      this.state = "ยกเลิก";
      this.theIndex = index;
      this.removeActive = true;

      // event.stopPropagation();
      // this.removeCode = "";
      // this.removeId = "";
      // this.removeActive = true;
      // this.removeCode = order.orderNumber;
      // this.removeId = order.orderHeaderId;
    },
    async removeOrder() {
      var resp = null;
      this.$vs.loading({ type: "radius" });
      try {
        resp = await this.$http.delete(
          this.$store.state.apiURL + "/api/orderheader/" + this.removeId,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        resp = null;
      }
      if (resp === null) {
        this.noticeError("ลบไม่สำเร็จ");
      } else {
        //  *** BEGIN : 2020-11-28 : Search By branches
        await this.reload();
        this.searchBtn();
        //  *** END : 2020-11-28 : Search By branches
        setTimeout(() => {
          this.$vs.loading.close();
          this.closeRemovePanel();
          this.noticeSuccess("ลบสำเร็จ");
        }, 1000);
      }
    },

    closeRemovePanel() {
      this.removeActive = false;
      this.removeCode = "";
      this.removeId = "";
    },

    adminEdit(event, orderid) {
      event.stopPropagation();
      window.open("/backoffice/rental/" + orderid, "_blank");
    },
    printSticker(event, orderid) {
      event.stopPropagation();
      window.open("/pages/sticker/" + orderid, "_blank");
    },
    printRec(event, orderid) {
      event.stopPropagation();
      window.open("/pages/rec2/" + orderid + "/f", "_blank");
    },
    editRental(event, orderid) {
      event.stopPropagation();
      window.open("/backoffice/rental-edit/" + orderid, "_blank");
    },
    async loadDetail(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },
    redirectTo(event, linkTo, orderid) {
      event.stopPropagation();
      window.open(linkTo + orderid, "_blank");
    },
    getRedirectLink(orderId) {
      return "/backoffice/order-detail/" + orderId;
    },
    async detailOneOrder(orderId) {
      var list = await this.allDetail.filter(x => x.orderHeaderId === orderId);
      // console.log("orderId >>> ", orderId, " list >>> ", list);
      return list;
    },
    theSearch(event, index) {
      this.activeb(event, index);
      this.searchBtn();
    },
    activeb(event, index) {
      //  *** BEGIN : 2020-11-28 : Search By branches
      var blist = this.getMyBranches;
      //  ***  END : 2020-11-28 : Search By branches
      this.branchCheckbox = [];

      if (event.target.className == "choose") {
        this.branchSelect[index] = false;
        event.target.className = "notChoose";
      } else {
        event.target.className = "choose";
        this.branchSelect[index] = true;
      }

      // ถ้า branchSelect ใส่ค่า
      for (var p = 0; p < blist.length; p++) {
        if (this.branchSelect[p] === true) {
          this.branchCheckbox.push(blist[p]);
        }
      }
      // console.log("this.branchSelect >>> ", this.branchSelect);
      // console.log("this.branchCheckbox >>> ", this.branchCheckbox);
    },
    searchBtn() {
      //  *** BEGIN : 2020-11-28 : Search By branches
      if(this.calendarData === null )
      {
        this.calendarData = {};
      }
      if (
        Object.entries(this.calendarData).length === 0
      ) {
        this.searchDate = null;
        this.searchDateEnd = null;
      }
      //  *** BEGIN : 2020-11-28 : Search By branches

      if (this.searchDate !== null && this.searchDateEnd !== null) {
        var searchDate = this.formatDate(this.searchDate);
        var searchDateEnd = this.formatDate(this.searchDateEnd);
        if (searchDate && searchDateEnd) {
          this.orders = this.searchByDate(
            this.orders,
            searchDate,
            searchDateEnd,
            "pickupDate"
          );
        }
        // console.log("AFTER searchByDate >>> ", this.orders);
      }
    },
    resetBtn() {
      this.searchDate = "";
      this.searchDateEnd = "";
      this.orders = this.original;
      this.calendarData = {};
    },
    async reload() {
      // var branch = null;
      var ord = [];
      // var theBranch = this.getCurrentBranch;

      this.permission = this.checkPermission(this.roles);
      // if (this.permission === "admin") {
      //   ord = await this.loadAllOrderShare();
      // } else {
      //   // branch = await this.findCurrentBranch(this.roles);
      //    //  *** BEGIN : 2020-11-28 : Search By branches
      //   ord = await this.loadOrderByBranchShared(this.getMyBranches);
      //    //  *** END : 2020-11-28 : Search By branches
      // }
      //  *** BEGIN : 2020-12-04 : load just branch occupied
      ord = await this.loadOrderByBranchObjectShared(this.getCurrentBranch);
      //  *** END : 2020-12-04 : load just branch occupied

      // ord = await this.loadOrderByBranchShared(theBranch.branchId);
      // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
      var theorder = await ord.filter(x => x.orderStatus === "รอรับชุด");
      var sorted = await this.descendingDateSortShare(
        theorder,
        "updateDateTime"
      );

      this.orders = await sorted;


      this.searchlist = await sorted;
      this.original = await sorted;

      // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
    },
    changeToReturn: function(event, order, index) {
      event.stopPropagation();
      this.bailPaymentMethod = order.bailPaymentMethod;
      this.orderHeaderId = order.orderHeaderId;
      this.state = order.orderStatus;
      this.theIndex = index;
      this.popupActive = true;
    },

    // *** BEGIN: RENTAL Payment update

    openRentalPaymentPanel(event, order, index) {
      event.stopPropagation();
      this.rentalPaymentMethod = order.rentalPaymentMethod;
      this.calendarRentalPayment = (order.rentalPaymentDate === null || order.rentalPaymentDate === null) ? this.formatDate(new Date()) :  order.rentalPaymentDate;

      this.rentalPaymentCode = this.rentalPaymentCode + 1;

      console.log("OPEN: this.calendarRentalPayment >>> ",this.calendarRentalPayment)

      this.orderHeaderId = order.orderHeaderId;
      this.state = order.orderStatus;
      this.theIndex = index;
      this.rentalPaymentActive = true;
    },
    closeRentalPaymentPanel() {
      this.rentalPaymentActive = false;
      this.resetValue();
    },
    changeRentalPayment(pay) {
      this.rentalPaymentMethod = pay;
    },

    async rentalPaymentUpdate() {
      this.$vs.loading({ type: "radius" });
      // SAVE HEADER
      // console.log("%%%% update calendarRentalPayment >>> ", this.calendarRentalPayment);

      // update datetime in case it less than 2001-01-01T00:00:00
      if(this.formatDate(this.calendarRentalPayment) <=   this.formatDate("2001-01-01T00:00:00")){
        this.calendarRentalPayment = this.formatDate(new Date());
      }

      var ord = {
        rentalPaymentMethod: this.rentalPaymentMethod,
        rentalPaymentChannel: this.rentalPaymentChannel,
        rentalPaymentDateTime: this.formatDate(this.calendarRentalPayment)
        // updateBy: this.currentUser,
      };

      // console.log("%%%% update ord >>> ", ord);

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/rental-payment/" +
            this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          await this.reload();
          //  *** BEGIN : 2020-11-28 : Search By branches
          this.searchBtn();
          //  *** BEGIN : 2020-11-28 : Search By branches
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
          }, 500);
          this.popupActive = false;
          this.rentalPaymentActive = false;
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 700);
      }

      // *** END: RENTAL Payment update

      // >>>> START TO SAVE HEADER
    },

    // *** BEGIN: Bail Payment update

    openBailPaymentPanel(event, order, index) {
      event.stopPropagation();
      // console.log("$$$$ openBailPaymentPanel order",order);
      this.bailPaymentMethod = order.bailPaymentMethod;
      this.calendarBailPayment = (order.bailPaymentDate === null || order.bailPaymentDate === null) ? this.formatDate(new Date()) :  this.formatDate(order.bailPaymentDate);

      ++(this.bailPaymentCode);

      this.orderHeaderId = order.orderHeaderId;
      this.state = order.orderStatus;
      this.theIndex = index;
      this.bailPaymentActive = true;
    },
    closeBailPaymentPanel() {
      this.bailPaymentActive = false;
      this.resetValue();
    },
    changeBailPayment(pay) {
      this.bailPaymentMethod = pay;
    },
    async bailPaymentUpdate() {
      this.$vs.loading();
      // SAVE HEADER

      // update datetime in case it less than 2001-01-01T00:00:00
      if(this.formatDate(this.calendarBailPayment) <=   this.formatDate("2001-01-01T00:00:00")){
        this.calendarBailPayment = this.formatDate(new Date());
      }

      var ord = {
        bailPaymentMethod: this.bailPaymentMethod,
        bailPaymentChannel: this.bailPaymentChannel,
        bailPaymentDateTime: this.formatDate(this.calendarBailPayment)
        // updateBy: this.currentUser,
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/bail-payment/" +
            this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          await this.reload();
          //  *** BEGIN : 2020-11-28 : Search By branches
          this.searchBtn();
          //  *** BEGIN : 2020-11-28 : Search By branches
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
          }, 500);
          this.popupActive = false;
          this.bailPaymentActive = false;
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 700);
      }

      // >>>> START TO SAVE HEADER
    },

    // *** END: Bail Payment update

    async update() {
      this.$vs.loading();
      // SAVE HEADER

      var ord = {
        updateBy: this.currentUser,
        orderStatus: this.state
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/received/" +
            this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          await this.reload();
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
          }, 500);
          this.popupActive = false;
          this.removeActive = false;
          this.bailPaymentActive = false;
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 700);
      }

      // >>>> START TO SAVE HEADER
    },
    resetValue() {
      this.bailPaymentMethod = "ไม่ระบุ";
      this.state = "ไม่ระบุ";
      this.theIndex = -1;
      this.takenoteIndex = -1;
      //*** 21Dec2021 */
      this.currentObject = {};
      this.remark = "";
    },
    closePanel() {
      this.popupActive = false;
      this.rentalPaymentActive = false;
      this.bailPaymentActive = false;
      this.resetValue();
    },

    changeStatus(state) {
      this.state = state;
    },
    changePayment(pay) {
      this.bailPaymentMethod = pay;
    },
    showPayment(pay) {
      if (
        pay === "เงินสด" ||
        pay === "โอนเงิน" ||
        pay === "Alipay/wechat" ||
        pay === "บัตรเครดิต" ||
        pay === "ช่องทางอื่น"
      ) {
        return pay;
      } else {
        return "ยังไม่ชำระ";
      }
    },
    showPaymentClass(pay) {
      // console.log('pay >>> ',pay);

      switch (pay) {
        case "เงินสด":
          return "label-btn-blue";

        case "โอนเงิน":
          return "label-btn-green";

        case "Alipay/wechat":
           return "label-btn-purple";

        case "บัตรเครดิต":
          return "label-btn-yellow";
        case "ช่องทางอื่น":
          return "label-btn-purple";
        default:
          return "label-btn-black";
      }
    },

    async findCurrentBranch(roles) {
      // >>>>>>> หา branch จาก  role ของ user >>>>>>>>>
      let branch_th = "1";
      var userrole = [];
      userrole = roles.find(x => x.roleName.includes("staff-"));
      if (userrole.length === 0 || userrole === undefined) {
        branch_th = "1";
      } else {
        var role_split = userrole.roleName.split("-");
        if (role_split[1] === null || role_split[1] === undefined) {
          branch_th = "1";
        }
        branch_th = role_split[1];
      }

      // console.log(" branch_th >>>> ", branch_th);

      var allBranches = await this.allBranch;

      var thisBranch = await allBranches.find(a => a.code === branch_th);

      return thisBranch;
    },

    findBranchName(id) {
      var b = this.allBranch.find(i => i.branchId === id);
      // console.log("@ BRANCH NAME >>> ", b, " ID >>> ", id);

      // return b.branchName;
      if (b === null || b === undefined) return "ไม่ระบุ";
      else return b.branchName;
    },

    async loadBranch() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
        // console.log("error", error);
      }

      return await response.data;
    },
    checkPermission(roles) {
      var pm = roles.filter(
        x =>
          x.roleName === "owner" ||
          x.roleName === "admin" ||
          x.roleName === "accountingadmin" ||
          x.roleName === "useradmin"
      );
      if (pm.length >= 1) {
        return "admin";
      } else {
        return "staff";
      }
    },
    confirmToChangeStatus(event) {
      event.stopPropagation();
      // this.orders[this.theIndex].orderStatus = "รับชุดแล้ว";
      // this.orders = this.orders.filter(x => x.orderStatus === "รอรับชุด");

      this.popupActive = false;
    },
    takeNote(event, tr) {
      event.stopPropagation();
      // console.log("@@@@@ TAKE NOTE >>> ", this.orders[index]);
      //*** 21Dec2021 */
      this.currentObject = {};
      this.currentObject = Object.assign({}, tr);
      this.remark = tr.remark1;
      this.notePopupActive = true;

      // var list = this.orders.filter(x => x.orderHeaderId === id);
      // if(list.length > 0){
      //   this.remark = list[0].remark1
      // }else{
      //   this.remark = '';
      // }
      // this.remark = this.orders[index].remark1;
    },

    //*** 21Dec2021 */
    async updatenote() {
      this.$vs.loading({ type: "radius" });
      var ordId = this.currentObject.orderHeaderId;

      var response;
      try {
        response = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/takenote1/" + ordId,
          {
            note: this.remark,
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );

        if (response.status === 200) {
          //  *** BEGIN : 2020-11-28 : Search By branches
          await this.reload();
          this.searchBtn();
          //  *** END : 2020-11-28 : Search By branches
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.popupActive = false;
            this.closeNotePanel();
          }, 300);
        }
      } catch (err) {
        this.noticeError(err);
      }
    },
    closeNotePanel() {
      this.notePopupActive = false;
      this.$vs.loading.close();
      this.resetValue();
    },

    selectAllOrders: function(event) {
      event.stopPropagation();

      var ind = 0;

      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0 || this.selectStatus === 2) {
        // console.log("expected >>> 0 or 2");
        this.selectedList = [];
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = true;
          this.selectedList.push(this.orders[ind]);
        }
        this.selectStatus = 1;
      } else if (this.selectStatus === 1) {
        // console.log("expected >>> 1");
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = false;
        }
        this.selectedList = [];
        this.selectStatus = 0;
      }
      this.fromSelectAll = true;
      // console.log(
      //   "SelectAll ==> " + this.selectAll + " STATUS  ===> " + this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    addSelectedList(indextt, event) {
      event.stopPropagation();
      // console.log(this.orders[indextt].selected);

      if (!this.orders[indextt].selected) {
        this.orders[indextt].selected = true;
        this.selectedList.push(this.orders[indextt]);
        // console.log("ADDED");
      } else {
        if (this.selectedList) {
          this.orders[indextt].selected = false;
          var foundIndextt = this.selectedList.findIndex(
            x => x.orderNumber === this.orders[indextt].orderNumber
          );
          this.selectedList.splice(foundIndextt, 1);
          // console.log("REMOVED");
        }
      }
      this.orders[indextt].selected = !this.orders[indextt].selected;
      // console.log(
      //   "SelectAll ==> " +
      //     this.selectAll +
      //     "TRUE STATUS  ===> " +
      //     this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    }
  },

  components: {
    Prism,
    Datepicker,
    "v-select": vSelect,
    Tabular,
    VueJsonPretty,
    // FunctionalCalendar
  },
  watch: {

    calendarRentalPayment(){
      // console.log("calendarRentalPayment >>> ",this.calendarRentalPayment);
      ++(this.rentalPaymentCode);
      // console.log("this.rentalPaymentCode >>> ",this.rentalPaymentCode);
    },

    calendarBailPayment(){
      ++(this.bailPaymentCode);
    },


    async getCurrentBranch() {
      await this.reload();
      this.searchBtn();
      // console.log('curent brach changed !!! >>> ',value.branchName);
    },

    calendarData: {
      handler(value) {

        let the_value = Object.assign({},value )
        if (Object.entries(the_value).length === 0 ) {
          // console.log("WATCH RENTALDATE IF ");
          this.searchDate = null;
          this.searchDateEnd = null;
        } else {
          // console.log("WATCH RENTALDATE ELSE ");
          this.searchDate = this.formatDate(value.start);
          this.searchDateEnd = this.formatDate(value.end);
        }




        // this.searchDate = this.formatDate(value.start);
        // this.searchDateEnd = this.formatDate(value.end);
      },
      deep: true
    },

    selectedList: function() {
      var ordersLength = this.orders.length;
      var selectedLength = this.selectedList.length;

      if (ordersLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN ordersLength == undefined");

        // console.log("SelectAll");
      }
      if (selectedLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN selectedLength == undefined)");
      }

      if (ordersLength == selectedLength && selectedLength > 0) {
        this.selectStatus = 1;
        if (this.fromSelectAll === false) this.selectAll = true;
        // console.log("IN ordersLength == selectedLength && selectedLength > 0");
        // console.log("SelectAll");
      } else {
        if (selectedLength == 0 || selectedLength == undefined) {
          this.selectStatus = 0;
          if (this.fromSelectAll === false) this.selectAll = false;
          // console.log("IN selectedLength == 0 || selectedLength == undefined");
        } else {
          this.selectStatus = 2;
          if (this.fromSelectAll === false) this.selectAll = true;
          // console.log("IN ELSE");
          // console.log("Select some");
        }
      }
      // console.log(this.orders);
      this.fromSelectAll = false;
      // console.log(
      //   "WACTCH selectedList >>> this.selectAll : " +
      //     this.selectAll +
      //     "  >>> this.selectStatus :  " +
      //     this.selectStatus
      // );

      // console.log(this.orders);
      // console.log(this.selectedList);
    }
    // branchCheckbox: function() {
    //   this.searchBtn();
    // }
  }
};
</script>

<style>

.rentalPay input{
  border: none;
  background-color: transparent;
  color: transparent;
}
.bailPay input{
  border: none;
  background-color: transparent;
  color: transparent;
}

.vs-textarea {
  height: 150px;
}

/* .rentalPayment{
 color: #cbcbcb; background-color: rgb(249 249 249); width:fit-content;
}

.bailPayment{
 color: #cbcbcb; background-color: rgb(249 249 249); width:fit-content;
}


.label-btn-blue{
 color: #0069ff !important; background-color:#e5efff!important; width:fit-content;
}

.label-btn-green{
 color :  #7ED321 !important; background-color: #7ed3212b  !important; width:fit-content;
}

.label-btn-yellow
{
  color: #f5a623 !important; background-color:#ffedd0  !important; width:fit-content;
} */

.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.changetoreturn {
  background: #f5a623;
  border: 1px solid #ad6c02;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}

/* v-select  */
.clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none !important;
}
.dropdown-menu {
  margin-top: 5 !important;
  background-color: white;
}
.selected-tag {
  width: 100% !important;
}
.vs__actions {
  display: none !important;
}
.holamundo > .vs-popup {
  width: 1000px !important;
}

.vfc-multiple-input input {
  width: 50% !important;
}

.vs__selected-options .vs__search {
  display: block !important;
}

/* v-select  */
</style>
