<template>
  <div class="tabular-container">
    <div class="page">
      <vs-row vs-w="12">
        <ul v-if="$acl.check('admin')" class="demo-alignment">
          <li v-for="(b, index) in branches" :key="index" style="margin-right:10px !important;">
            <vs-radio v-model="branchrad" :vs-value="b.branchId">
              {{
              b.branchName
              }}
            </vs-radio>
          </li>
        </ul>
      </vs-row>
      <vs-row v-for="(item, index) in rowItems" :key="index" vs-w="12" style="margin-top: 10px;">
        <vs-col
          v-for="(colitem, colind) in colItems"
          :key="colind"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          style="width: 20%; height: 100% !important;"
        >
          <div
            v-if="index * COLS + colind >= SPACE"
            class="grid-layout-container alignment-block"
            style="vertical-align:text-top ; width:100% !important; height:100% !important; "
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            :data-seq="setSeq(index * COLS + colind)"
            :class="['notChoose', 'init']"
            @click="blueLight($event, index * COLS + colind + 1)"
          >{{ index * COLS + colind + 1 }}</div>
        </vs-col>
      </vs-row>
    </div>
    <!--------------------------------------------------------------------->
    <!--------------------------------------------------------------------->
    <div style=" margin-bottom:30px; "></div>
    <!--------------------------------------------------------------------->
    <div style="text-align: center;">
      <vs-button
        @click="saveSeq(seq)"
        style="margin-right: auto; margin-left: auto;"
        color="danger"
        type="filled"
      >บันทึกลำดับการพิมพ์</vs-button>
    </div>

    <!--------------------------------------------------------------------->
  </div>
</template>

<script>
import shapeFormat from "@/mixins/shapeFormat.js";
export default {
  mixins: [shapeFormat],
  data() {
    return {
      branches: [],
      branchrad: "",
      orderHeaderId: "",
      watermark: true,
      dlist: [],
      orderheader: {},
      position: 0,
      COLS: 5,
      ROWS: 0,
      LINEPERPAGE: 12,
      // ALLS: 60,
      REMAINDER: 0,
      totalRecty: 0, // จำนวน sticker + เว้นว่าง
      SPACE: 0, // จำนวนเว้นว่าง
      COUNTINDEX: 0,
      rowItems: [],
      colItems: [],
      remainderItems: [],
      seq: 0
    };
  },
  watch: {
    async branchrad(value) {
      // FIND THIS BRANCH
      this.branchrad = value;
      var getseq = await this.getOne("/api/systemconfig/getsticker/" + value);
      // console.log("branchID >>> ", value, " getseq : ", getseq);

      this.newBlueLight(Number(getseq));
      // var grid = document.getElementsByClassName("init");
      // console.log('document.getElementsByClassName("init")', grid);

      // for (var i = 0; i < grid.length; i++) {
      //   grid[i].className = "notChoosePosition init";
      //   console.log("grid[" + i + "] >>> ", grid[i].className);
      // }
      // setTimeout(() => {
      //   this.initBlueLight(getseq);
      // }, 700);
    }
  },
  async created() {
    this.branches = await this.getList("/api/branch");
    this.branchrad = this.$store.state.auth.mybranchid;
  },
  async mounted() {
    var getseq = await this.getOne(
      "/api/systemconfig/getsticker/" + this.$store.state.auth.mybranchid,
      this.$store.state.auth.apiHeader
    );

    setTimeout(() => {
      this.initBlueLight(getseq);
    }, 700);
    this.rowItems = this.numRange(this.LINEPERPAGE);
    this.colItems = this.numRange(this.COLS);
  },
  methods: {
    setId(number) {
      return "bl-" + number.toString();
    },
    setSeq(number) {
      return number.toString();
    },
    selbranch(id) {
      console.log("branch id >>> ", id);
    },
    initBlueLight(getseq) {
      var getnum = Number(getseq);
      if (isNaN(getnum) || getnum < 0) {
        getnum = 1;
      }
      var grid = document.getElementsByClassName("init");
      for (var i = 0; i < grid.length; i++) {
        grid[i].className = "notChoosePosition init";
      }
      grid[getnum - 1].className = "choosePosition init";
    },
    async saveSeq(seq) {
      // console.log("seq >>>> ", seq);

      var res = await this.getOne(
        "/api/systemconfig/setsticker/" + this.branchrad + "/" + seq
      );
      if (res === null) {
        this.$emit("setposition", false);
      } else {
        this.$emit("setposition", true);
      }
    },
    blueLight(event, seq) {
      event.stopPropagation();
      var grid = document.getElementsByClassName("choosePosition");
      for (var k = 0; k < grid.length; k++) {
        grid[k].className = "notChoose init";
        // console.log("grid[" + k + "] : ", grid[k]);
      }
      // alert(event.target.className);

      if (event.target.className === "choosePosition") {
        event.target.className = "notChoose init";
      } else {
        event.target.className = "choosePosition init";
      }
      this.seq = seq.toString();
    },

    newBlueLight(seq) {
      seq = isNaN(Number(seq)) ? 1 : Number(seq);
      seq = seq - 1;
      var grid = document.getElementsByClassName("init");
      for (var k = 0; k < grid.length; k++) {
        grid[k].className = "notChoose init";

        // console.log(
        //   "grid[" + k + "] : ",
        //   grid[k].dataset.seq,
        //   " class ",
        //   grid[k].className
        // );
      }
      // alert(event.target.className);

      grid[Number(seq)].className = "choosePosition init";

      // if (event.target.className === "choosePosition") {
      //   event.target.className = "notChoose";
      // } else {
      //   event.target.className = "choosePosition";
      // }
      this.seq = seq.toString();
      // console.log("!!! NOWWW !!!! seq : ", this.seq);
    }
  },
  components: {}
};
</script>
<style scoped>
.theborder {
  border: solid 1px black;
  width: 38mm;
  height: 22mm;
  font-size: 9px;
  align-content: center;
  padding-top: 17px;
}

.tabular-container {
  background-color: white !important;
  width: 800px;
  padding: 0 !important;
  margin: 0 auto;
}

.page {
  width: 210mm;
  min-height: 150 mm;
  padding: 0;
  margin: 0 auto;
  /* border: 1px #d3d3d3 solid; */
  /* border-radius: 5px; */
  background: white;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 295mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    height: initial;
    box-shadow: initial;
    background: initial;
    /* page-break-after: always; */
  }
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
</style>
